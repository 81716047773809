<template>
  <div class="aboutUs">

              <div class="ql-container ql-snow">
          <div class="ql-editor" v-html="editorValue">
          </div>
        </div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/assets/css/font.css"
import {
  gkzyCommonParameters
} from "@/api/commonparams"
export default {
  name: 'aboutUs',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      editorValue:''
    }
  },
  computed: {},
  watch: {},
  created () {
      gkzyCommonParameters({ parameterType: '关于我们' }).then(res=>{
          this.editorValue = res.rows[0].parameterValue
      })
  },
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="scss">
.aboutUs{
    padding: 15px;
    // overflow-y: auto;
    // height: 860px;
    .ql-editor{
      line-height: 1.72!important;
    }
}
</style>
